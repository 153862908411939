import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import Img from "gatsby-image";
import Footer from "src/components/global/Footer.js";
import HeaderTop from "src/components/project/HeaderTop.js";
import Imgs from "src/components/project/Imgs.js";
import Text from "src/components/project/Text.js";
import Testimonial from "src/components/global/Testimonial.js";
import About from "src/components/project/About.js";
import LinkUrl from "src/components/project/LinkUrl.js";
import WwwLabel from "src/components/project/WwwLabel.js";
import Credentials from "src/components/project/Credentials.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import MainWrapper from "src/components/global/MainWrapper.js";

import main from "src/assets/styles/main.js";
import Button from "src/components/global/Button.js";

import { Link } from "gatsby";
import { gsap } from "gsap";
import slugify from "slugify";

const Bg = styled.div`
  background-color: ${(props) => (props.bgCol ? props.bgCol : colors.dark)};
  padding-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 15rem;
  }
`;

const ContentWrapper = styled.div``;
const BottomRow = styled.div`
  margin-top: ${distances.sectionMarginBig}rem;

  display: flex;
  align-items: center;
  margin-bottom: ${distances.sectionMarginBig}rem;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const NextLink = styled(Link)`
  z-index: 10;
  text-decoration: none;
`;

const BidTextNext = styled.span`
  font-size: 20vw;
  color: inherit;
  color: ${(props) => (props.color ? props.color : colors.white)};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const NextLinkText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.white)};
`;

const NextWrapper = styled.div`
  position: relative;
  :hover {
    ${BidTextNext} {
      opacity: 0.1;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const ImgWrapper = styled.div`
  position: fixed;

  cursor: none;

  z-index: 100;

  pointer-events: none;
  opacity: 0;
  top: 100rem;
  left: 0rem;
  transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1),
    top 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.onHover {
    top: 0rem;
    left: 0rem;
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      top 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    /* background-color: blue; */
  }
`;

const StyledImgNext = styled(Img)`
  width: 500rem;
  height: 300rem;
  border-radius: ${main.radius}rem;
`;

const TextWrapprNext = styled(ImgWrapper)`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: none;
  &.onHover {
    transition: opacity 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1),
      top 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);

    /* background-color: blue; */
  }
`;

const TextNext = styled.div`
  position: relative;
  background-color: var(--rest);
  padding: ${distances.gap}rem;
  border-radius: ${main.radius}rem;
  display: block;
  max-width: 300rem;
  transform: translate(-50%, calc(-50% + 300rem));
  z-index: 900;
  span {
    color: var(--bg);
    font-size: ${typographySizes.s}rem;
  }
`;

const Project = ({ data, pageContext }) => {
  const [onSlider, setOnSlider] = useState(false);

  const boxRef = useRef();
  const boxRefIn = useRef();
  const nextWrapper = useRef();

  const slugTitle = slugify(data.nextPost.Title, {
    lower: true,
    strict: true,
  });

  const urlLang =
    pageContext.locale === "pl"
      ? "/projekty/"
      : `/${pageContext.locale}/works/`;
  const nextUrl = urlLang + slugTitle;
  const langUrl =
    data.nextPost.locale == "pl" ? "" : `/${data.nextPost.locale}`;
  const allWorksUrl = data.strapiPageWork.Page_name
    ? `${langUrl}/${slugify(data.strapiPageWork.Page_name, {
        lower: true,
        strict: true,
      })}`
    : "";
  const isBrowser = typeof window !== "undefined";
  let desktop = true;
  if (isBrowser) {
    if (isBrowser && window.innerWidth < 768) {
      desktop = false;
    }
  }

  const movecursor = (e) => {
    // console.log(e.clientY - boxfrom);
    // // console.log(position);

    gsap.to(boxRef.current, {
      duration: 0.5,
      x: e.clientX - 250,
      y: e.clientY - 150,
    });
    gsap.to(boxRefIn.current, {
      duration: 0.7,
      x: e.clientX - 250,
      y: e.clientY - 150,
    });
  };

  const onEnterFn = () => {
    desktop && setOnSlider(true);
  };

  const onLeaveFn = () => {
    desktop && setOnSlider(false);
  };
  return (
    <Bg bgCol={data.strapiProject.Background_color}>
      <Seo
        title={`we3studio : ${data.strapiProject.Title}`}
        description={data.strapiProject.Intro_text}
        image={data.strapiProject.Thumbnail_for_next.localFile.publicURL}
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
        catalog={`${pageContext.catalog}/`}
        colorBg={data.strapiProject.Text_dark ? colors.dark : colors.white}
        colorText={data.strapiProject.Text_dark ? colors.white : colors.dark}
        backBtnUrl={allWorksUrl}
      />
      <MainWrapper>
        <HeaderTop data={data} />
      </MainWrapper>
      <ContentWrapper>
        {data.strapiProject.Www_link && (
          <WwwLabel
            url={data.strapiProject.Www_link.Link}
            lang={pageContext.locale}
            text={data.strapiProject.Www_link.Label}
            img={data.strapiProject.Www_link.Img}
            Text_dark={data.strapiProject.Text_dark}
          />
        )}
        {data.strapiProject.Content.map((pos, index) => {
          if (pos.strapi_component === "project.img-one") {
            return <Imgs img={pos} />;
          }
          if (pos.strapi_component === "project.testimonial") {
            return (
              <Testimonial
                lang={pageContext.locale}
                colorBg={
                  data.strapiProject.Text_dark ? colors.dark : colors.white
                }
                colorText={
                  data.strapiProject.Text_dark ? colors.white : colors.dark
                }
                testimonial={pos.testimonial}
              />
            );
          }
          if (pos.strapi_component === "project.text") {
            return <Text text={pos} TextDark={data.strapiProject.Text_dark} />;
          }
          if (pos.strapi_component === "project.link") {
            return (
              <LinkUrl
                index={index}
                text={pos.Label}
                url={pos.Url}
                pos={pos.Pos}
                TextDark={data.strapiProject.Text_dark}
              />
            );
          }
          if (pos.strapi_component === "project.about") {
            return (
              <About
                about={pos}
                TextDark={data.strapiProject.Text_dark}
                lang={pageContext.locale}
              />
            );
          }
          if (pos.strapi_component === "project.credentials") {
            return (
              <Credentials
                positions={pos.Position}
                TextDark={data.strapiProject.Text_dark}
              />
            );
          }
          // Default value to be returned if none of the conditions are met
          return null;
        })}
      </ContentWrapper>
      <MainWrapper onPointerMove={movecursor}>
        <BottomRow>
          <Button
            to={allWorksUrl}
            color={data.strapiProject.Text_dark ? colors.dark : colors.white}
          >
            {data.nextPost.locale == "pl"
              ? "              zobacz wysztkie projekty"
              : `see all projects`}
          </Button>
          <NextWrapper
            ref={nextWrapper}
            onMouseEnter={onEnterFn}
            onMouseLeave={onLeaveFn}
          >
            <NextLink to={nextUrl}>
              <NextLinkText
                color={
                  data.strapiProject.Text_dark ? colors.dark : colors.white
                }
              >
                <ImgWrapper ref={boxRef} className={onSlider && "onHover"}>
                  <StyledImgNext
                    loading="eager"
                    fluid={
                      data.nextPost.Thumbnail_for_next.localFile.childImageSharp
                        .fluid
                    }
                  />
                </ImgWrapper>
                <TextWrapprNext
                  ref={boxRefIn}
                  className={onSlider && "onHover"}
                >
                  <TextNext className={onSlider && "onHover"}>
                    <span>{data.nextPost.Title}</span>
                  </TextNext>
                </TextWrapprNext>
                <BidTextNext
                  color={
                    data.strapiProject.Text_dark ? colors.dark : colors.white
                  }
                >
                  next
                </BidTextNext>
              </NextLinkText>
            </NextLink>
          </NextWrapper>
        </BottomRow>
      </MainWrapper>
      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        colorBg={data.strapiProject.Text_dark ? colors.dark : colors.white}
        colorText={data.strapiProject.Text_dark ? colors.white : colors.dark}
        lang={pageContext.locale}
      />
    </Bg>
  );
};

export default Project;

export const ProjectQ = graphql`
  query Project($locale: String!, $id: String!, $nextId: String!) {
    nextPost: strapiProject(id: { eq: $nextId }) {
      Title
      locale
      Thumbnail_for_next {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
    }
    strapiProject(locale: { eq: $locale }, id: { eq: $id }) {
      Title
      Background_color
      Text_dark
      Intro_text
      Www_link {
        Link
        Label
        Img {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      Thumbnail_for_next {
        localFile {
          publicURL
        }
      }
      Content {
        ... on STRAPI__COMPONENT_PROJECT_LINK {
          Label
          Url
          Pos
          strapi_component
        }
        ... on STRAPI__COMPONENT_PROJECT_ABOUT {
          Publications_title
          publications {
            Publications_img {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Publications_url
          }
          Awards {
            Link
            Project_graphic {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          strapi_component
          Description_first_col
          Description_second_col
          Title_first_col
          Title_main
          Title_second_col
        }

        ... on STRAPI__COMPONENT_PROJECT_TESTIMONIAL {
          id
          strapi_component
          testimonial {
            Company_name
            Featured_text

            Home_page
            Person_name
            Clutch
            Testimonial {
              ... on STRAPI__COMPONENT_TESTIMONIAL_TEXT {
                Testimonial {
                  data {
                    Testimonial
                  }
                }
                strapi_component
              }
              ... on STRAPI__COMPONENT_TESTIMONIAL_VIDEO {
                Video {
                  localFile {
                    url
                  }
                }
                strapi_component
              }
            }
            Logo {
              width
              height
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Person_img {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_PROJECT_TEXT {
          Big_text
          Small_text
          strapi_component
        }
        ... on STRAPI__COMPONENT_PROJECT_CREDENTIALS {
          strapi_component
          Position {
            Position_name
            Name
          }
        }
        ... on STRAPI__COMPONENT_PROJECT_IMG_ONE {
          Different_bg_color
          Img_100_width
          Two_columns
          strapi_component
          Imgs {
            alternativeText
            ext
            localFile {
              url
              childImageSharp {
                fluid(
                  maxWidth: 3080
                  quality: 100
                  webpQuality: 100
                  jpegQuality: 100
                  pngQuality: 100
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      categories {
        Name
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
