import React from "react";
import Img from "gatsby-image";
import MainWrapper from "src/components/global/MainWrapper.js";

import styled from "styled-components";
import SectionMargin from "src/components/project/SectionMargin.js";
import main from "src/assets/styles/main.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

// markup

const BigImg = styled(Img)`
  border-radius: ${main.radius}rem;
  width: ${(props) => (props.twoCol == true ? "calc(50% - 25rem)" : "100%")};
  align-self: flex-start;
  margin-bottom: 50rem;
  margin-bottom: ${(props) => (props.twoCol == true ? "0" : "50rem")};
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 25rem;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`;

const StyledMain = styled(MainWrapper)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const StyledVideo = styled.video`
  width: ${(props) => (props.twoCol == true ? "calc(50% - 25rem)" : "100%")};
  border-radius: ${main.radius}rem;
  height: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 25rem;
  }
`;
const StyledVideo100 = styled.video`
  width: 100%;
`;
const ColorBackground100 = styled.div`
  background-color: ${(props) => props.bgColor};
  padding-top: ${(props) => (props.bgColor ? "50rem" : "0")};
  padding-bottom: ${(props) => (props.bgColor ? "50rem" : "0")};
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${(props) => (props.bgColor ? "25rem" : "0")};
    padding-bottom: ${(props) => (props.bgColor ? "25rem" : "0")};
  }
`;

const ColorBackground = styled(StyledMain)`
  background-color: ${(props) => props.bgColor};
  padding-top: 50rem;
  padding-bottom: 50rem;
  ${BigImg} {
    width: ${(props) => (props.twoCol == true ? "calc(50% - 25rem)" : "100%")};
    align-self: flex-start;
    margin-bottom: ${(props) => (props.twoCol == true ? "0" : "50rem")};
    :last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      width: 100%;
      margin-bottom: 25rem;
    }
  }
  ${StyledVideo} {
    width: ${(props) => (props.twoCol == true ? "calc(50% - 25rem)" : "100%")};
    align-self: flex-start;
    margin-bottom: ${(props) => (props.twoCol == true ? "0" : "50rem")};
    :last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      width: 100%;
      margin-bottom: 25rem;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 25rem;
    padding-bottom: 25rem;
  }
`;

const Imgs = ({ img }) => {
  const w100 = img.Img_100_width;
  const bgColor = img.Different_bg_color;
  const twoCol = img.Two_columns;
  return (
    <SectionMargin>
      {/* Just normal * * * * * * * * * * * * * * * * * */}
      {w100 != true && (bgColor == null || bgColor == false) && (
        <StyledMain>
          {img.Imgs.map((pos) => {
            return (
              <>
                {pos.localFile.childImageSharp !== null ? (
                  <BigImg
                    twoCol={twoCol}
                    loading="eager"
                    fluid={pos.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <StyledVideo autoPlay muted loop playsInline twoCol={twoCol}>
                    <source src={pos.localFile.url} type="video/mp4" />
                  </StyledVideo>
                )}
              </>
            );
          })}
        </StyledMain>
      )}
      {/* Diffrent background color * * * * * * * * * * * * * * * * * */}
      {bgColor != false && bgColor != null && w100 != true && (
        <ColorBackground bgColor={bgColor} twoCol={twoCol}>
          {img.Imgs.map((pos) => {
            return (
              <>
                {pos.localFile.childImageSharp !== null ? (
                  <BigImg
                    loading="eager"
                    fluid={pos.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <StyledVideo autoPlay muted loop playsInline twoCol={twoCol}>
                    <source src={pos.localFile.url} type="video/mp4" />
                  </StyledVideo>
                )}
              </>
            );
          })}
        </ColorBackground>
      )}
      {/* 100% width * * * * * * * * * * * * * * * * * */}
      {w100 == true && (
        <ColorBackground100 bgColor={bgColor}>
          {img.Imgs.map((pos) => {
            return (
              <>
                {pos.localFile.childImageSharp !== null ? (
                  <Img
                    loading="eager"
                    fluid={pos.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <StyledVideo100 autoPlay muted loop playsInline>
                    <source src={pos.localFile.url} type="video/mp4" />
                  </StyledVideo100>
                )}
              </>
            );
          })}
        </ColorBackground100>
      )}
    </SectionMargin>
  );
};

export default Imgs;
